@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.switch-loading-animation {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.switch-loading-animation::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    transparent,
    rgba(59, 133, 251, 1),
    transparent 30%
  );
  animation: rotate 1s linear infinite;
}

.switch-loading-animation::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 1.5px;
  top: 1.5px;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  background: #f2f2f2;
  border-radius: 2rem;
}

.switch-loading-animation-dark::after {
  background: #1d1d1d;
}

@keyframes opacityChange {
  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
